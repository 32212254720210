// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/root.jsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/root.jsx");
  import.meta.hot.lastModified = "1724763426906.1292";
}
// REMIX HMR END

import { Links, LiveReload, Meta, Outlet, Scripts, ScrollRestoration } from "@remix-run/react";
import { useEffect } from "react";
export default function App() {
  _s();
  useEffect(() => {
    try {
      window.$crisp = [];
      window.CRISP_WEBSITE_ID = "f2fbf04e-52f0-4818-83ec-ecd33dc5e388";
      (function () {
        var d = document;
        var s = d.createElement("script");
        s.src = "https://client.crisp.chat/l.js";
        s.async = 1;
        d.getElementsByTagName("head")[0].appendChild(s);
      })();
    } catch (error) {
      console.error("Error loading Crisp chat script:", error);
    }
  }, []);
  return <html>
      <head>    
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <Meta />
        <Links />
      <title>SPIDER Website SEO Optimizer</title>
      </head>
      <body>
        <Outlet />
        <ScrollRestoration />
        {/* <LiveReload /> */}
        <Scripts />
      </body>
    </html>;
}
_s(App, "OD7bBpZva5O2jO+Puf00hKivP7c=");
_c = App;
var _c;
$RefreshReg$(_c, "App");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;